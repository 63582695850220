@import 'admin/sizes';
@import 'admin/colors';

.custom-cluster.mat-table {
  //.mat-sort-header-button {
  //  align-items: start !important;
  //}

  .mat-header-cell {
    z-index: 0;
  }
}

.default.mat-table {
  font-family: 'Open Sans', sans-serif;
  min-width: 100%;

  &.full-borders {
    .mat-header-cell {
      border-right: 1px solid $admin-table-header-border-color;
    }

    .mat-header-cell:first-child {
      border-left: 1px solid $admin-table-header-border-color;
    }

    .mat-row .mat-cell,
    .mat-footer-row .mat-footer-cell {
      border-right: 1px solid $admin-table-header-border-color;
      border-bottom: 1px solid $admin-table-header-border-color;
    }

    .mat-footer-row .mat-footer-cell {
      border-top: none;
    }

    .mat-row .mat-cell:first-child,
    .mat-footer-row .mat-footer-cell:first-child {
      border-left: 1px solid $admin-table-header-border-color;
    }
  }

  .min-table-width {
    min-width: 120px;
  }

  .mat-header-row {
    text-align: left;
    height: auto;

    .mat-header-cell {
      border-top: 1px solid $admin-table-header-border-color;
      border-bottom: 1px solid $admin-table-header-border-color;
      font-size: 12px;
      font-weight: 600;
      color: $admin-table-text-color;
      padding: $admin-table-padding-size !important;

      &.action-column {
        width: 32px;
      }

      &.no-bottom {
        border-bottom: none;
      }

      &.no-top {
        border-top: none;
      }

      &.no-left {
        border-left: none;
      }

      &.center {
        text-align: center;

        .mat-sort-header-container {
          justify-content: center;
        }
      }

      .mat-sort-header-container {
        button {
          text-align: left;
        }
      }
    }
  }

  .mat-footer-row {
    text-align: left;
    height: auto;

    .mat-footer-cell {
      font-size: 12px;
      font-weight: 600;
      color: $admin-table-text-color;
      padding: $admin-table-padding-size !important;
      border-top: 1px solid $admin-table-header-border-color;
      border-bottom: none;

      &.center {
        text-align: center;
      }
    }
  }

  &.group-borders-2 {
    tbody {
      .mat-row:not(:nth-last-child(2)) td:first-child {
        border-bottom: 1px solid #e5eaf1 !important;
      }

      .mat-row:nth-child(2n):not(:last-child) td {
        border-bottom: 1px solid #e5eaf1 !important;
      }
    }
  }

  &.group-borders-3 {
    tbody {
      .mat-row:not(:nth-last-child(3)) td:first-child {
        border-bottom: 1px solid #e5eaf1 !important;
      }

      .mat-row:nth-child(3n):not(:last-child) td {
        border-bottom: 1px solid #e5eaf1 !important;
      }
    }
  }

  &.group-borders-4 {
    tbody {
      .mat-row:not(:nth-last-child(4)) td:first-child {
        border-bottom: 1px solid #e5eaf1 !important;
      }

      .mat-row:nth-child(4n):not(:last-child) td {
        border-bottom: 1px solid #e5eaf1 !important;
      }
    }
  }

  &.group-any-bg {
    tbody {
      tr {
        td:first-child {
          background-color: white;
          border-bottom: 1px solid #e5eaf1 !important;
        }
      }
    }
  }

  .mat-row {
    height: auto;

    &.clickable {
      cursor: pointer;
    }

    &:nth-child(even) {
      background-color: $admin-table-bg-color;
    }

    .mat-cell {
      font-size: 12px;
      color: $admin-table-text-color;
      padding: $admin-table-padding-size !important;
      border: none;

      &.error {
        max-width: 400px;
        word-break: break-all;
      }

      &.break-words {
        word-break: break-all;
      }

      &.center {
        text-align: center;
      }

      &.action-column {
        .action-button {
          .mat-icon {
            font-size: 18px;
          }
        }
      }

      &.icon {
        text-align: center;

        .mat-icon-button {
          width: 24px;
          height: 24px;
          line-height: 24px;

          &.settings {
            margin-right: 8px;
          }

          &.blue {
            color: $admin-button-blue;
          }

          &.red {
            color: $admin-button-red;
          }
        }

        .mat-icon {
          width: 18px;
          height: 18px;
          font-size: 18px;
          line-height: 18px;
        }
      }

      &.grey {
        opacity: 0.6;
      }
    }
  }
}

.dropdown-field.mat-form-field {
  &.mat-focused {
    .mat-form-field-wrapper {
      border: 1px solid #4aade5 !important;
      box-shadow: 0 0 4px rgba(74, 173, 229, 0.8);
    }
  }

  .mat-form-field-wrapper {
    padding: 0;
    background-color: $content-fields-bg-edit-color;
    border: 1px solid $content-fields-border-edit-color;
    border-radius: 3px;
    align-items: center;
    height: 30px;
    // padding-top: 4px;
    // padding-left: 4px;

    .mat-form-field-infix {
      border-top: none;
      font-size: 11px;
      align-items: center;
      width: auto;
      //padding: 9px 4px;

      .mat-select {
        font-family: 'Nunito', sans-serif !important;
        //line-height: 15px;
        padding: 0 4px;
        width: calc(100% - 8px);

        .mat-select-trigger {
          // width: 100%;
          // display: inline-block;
          // vertical-align: top;
          display: flex;
          align-items: center;

          .mat-select-value {
            max-width: none;
            width: calc(100% - 18px);
            display: inline-block;

            .mat-select-value-text {
              position: absolute;
              top: 20%;
            }
          }

          .mat-select-arrow-wrapper {
            width: 18px;
            display: inline-block;
            vertical-align: top;
            margin-top: 6px;
          }
        }
      }

      input {
        line-height: 15px;
        font-family: 'Nunito', sans-serif !important;
        padding: 0 8px;
        width: calc(100% - 28px);
      }

      .mat-form-field-label-wrapper {
        //top: 50%;
        left: 0.5em;
        font-size: 12px;
        transform: translateY(10%);
      }
    }

    .mat-form-field-suffix {
      position: absolute;
      bottom: 5px;
      right: 4px;
    }

    .mat-form-field-subscript-wrapper {
      margin: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}
