/* HUB View */
$hub-view-padding: 22px;
$hub-card-border-radius: 4px;
$hub-card-margin: 8px;
$hub-card-title-margin-v: 4px;
$hub-card-title-margin-h: 8px;
$hub-card-title-height: 26px;
$hub-card-subheading-height: 17px;

$hub-back-button-top: 52px;

$hub-summary-card-height: 180px;
$hub-summary-card-padding: 24px;
$hub-summary-card-padding-top: 16px;
$hub-summary-card-padding-bottom: 8px;
$hub-summary-icon-container-width: 82px;
$hub-summary-table-width: 384px;
$hub-summary-table-padding: 8px;
$hub-summary-table-cell-padding: 4px;

$hub-enrolment-card-height: 119px;
$hub-enrolment-card-padding: 12px;
$hub-enrolment-card-padding-bottom: 8px;
$hub-enrolment-sparkline-margin: 35px;
$hub-enrolment-growth-width: 64px;
$hub-enrolment-subtitle-margin: 4px;

$hub-student-profile-chart-height: 28px;
$hub-student-profile-card-height: 144px;
$hub-student-profile-table-width: 418px;
$hub-student-profile-card-padding: 12px;
$hub-student-profile-card-padding-bottom: 8px;
$hub-student-profile-card-enrolment-panel-margin: 12px;

$hub-portfolio-card-height: 156px; //was 121
$hub-portfolio-card-padding: 8px;
$hub-portfolio-card-w-title-padding: 24px;
$hub-portfolio-circle-width: 32px;
$hub-portfolio-circle-margin: 2px;
$hub-portfolio-dollars-item-margin: 16px;
$hub-portfolio-dollars-total-margin: 24px;
$hub-portfolio-dollars-bottom-margin: 12px;
$hub-portfolio-status-item-margin: 16px;

$hub-demographics-card-height: 126px;
$hub-demographics-card-padding-h: 24px;
$hub-demographics-card-padding-v: 8px;
$hub-demographics-card-population-icon-size: 36px;
$hub-demographics-card-population-icon-height: 18px;
$hub-demographics-card-population-margin: 8px;
$hub-demographics-card-household-icon-size: 26px;
$hub-demographics-card-household-icon-height: 14px;
$hub-demographics-card-household-icon-margin: 12px;

$hub-minor-works-card-height: 153px;
$hub-minor-works-card-padding: 12px;
$hub-minor-works-card-padding-bottom: 8px;

$hub-early-childhood-card-height: 170px;

$hub-gnaf-card-height: 170px;
$hub-gnaf-chart-height: 78px;

$hub-occupation-card-height: 214px;
$hub-occupation-chart-height: 180px;

$hub-industries-card-height: 174px;
$hub-industries-chart-height: 140px;

$hub-housing-supply-card-height: 129px;
$hub-housing-supply-chart-height: 75px;

$hub-table-first-cell-padding: 4px 8px;
$hub-table-options-margin: 8px;
$hub-table-download-button-width: 28px;
$hub-table-download-button-height: 28px;
$hub-table-download-button-icon-size: 16px;
$hub-table-tooltip-size: 12px;
$hub-table-tooltip-margin: 4px;

$hub-school-view-sv-height: 180px;
$hub-school-view-info-padding: 18px;
$hub-school-view-title-margin: 4px;
$hub-school-view-section-margin: 18px;
$hub-school-view-warning-margin: 12px;
$hub-school-view-warning-padding: 8px;
$hub-school-view-warning-icon-size: 16px;
$hub-school-view-warning-icon-margin: 10px;
$hub-school-vie-hr-margin: 8px;
$hub-school-view-section-title-margin: 8px;
$hub-school-view-section-subtitle-margin: 4px;
$hub-school-view-content-margin: 8px;
$hub-school-view-content-value-panel-margin: 18px;
$hub-school-view-content-value-margin: 4px;
$hub-school-view-doc-container-margin: 8px;
$hub-school-view-doc-icon-height: 16px;
$hub-school-view-doc-container-label-padding: 8px;
$hub-school-view-enrolment-arrow-width: 18px;
$hub-school-view-enrolment-arrow-left-margin: 5px;
$hub-school-view-enrolment-arrow-right-margin: 11px;
$hub-school-view-enrolment-panel-height: 82px;
$hub-school-view-housing-supply-panel-height: 56px;
$hub-school-view-floor-plan-panel-height: 36px;
$hub-school-view-naplan-panel-height: 40px;
$hub-school-view-enrolment-value-width: 40px;
$hub-school-view-card-year-margin: 4px;
$hub-school-view-enrolment-card-margin: 8px;
$hub-school-view-selected-enrolment-top: -28px;
$hub-school-view-selected-enrolment-padding: 4px 4px 4px 6px;
$hub-school-view-selected-enrolment-cross-size: 16px;
$hub-school-view-selected-enrolment-margin-left: 2px;
$hub-school-view-selected-enrolment-margin-top: -2px;

$hub-school-view-enrolment-chart-height: 320px;
$hub-school-view-naplan-chart-height: 280px;
$hub-school-view-enrolment-chart-margin: 8px;

$hub-school-view-enrolment-export-margin: 8px;
