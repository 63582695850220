@media only screen and (max-width: 800px) {
  .info-section {
    padding-top: 32px !important;
    width: 100% !important;

    .tagline {
      margin: 32px 0 !important;

      .tag {
        font-size: 32px !important;
      }

      .blurb {
        font-size: 14px !important;
      }
    }

    .buttons {
      margin-bottom: 0 !important;
      left: auto !important;

      .mat-button {
        width: 140px !important;
      }
    }
  }
}
