@import 'hub/sizes';
@import 'hub/colors';

@media (max-height: 1430px) {
  .hub-card-container:not(.expanded) {
    .hub-card-panel.bottom-card {
      .more-schools {
        .panel {
          bottom: calc(#{$hub-summary-table-padding} + 26px);
        }
      }
    }
  }
}

.hub-table {
  .mat-table {
    font-family: 'Open Sans', sans-serif;
    min-width: 100%;

    .mat-header-row {
      text-align: left;
      height: auto;

      .mat-header-cell {
        border-top: 1px solid $hub-table-header-border-color;
        border-bottom: 1px solid $hub-table-header-border-color;
        font-size: 10px;
        font-weight: 600;
        color: $hub-card-value-color;
        padding: $hub-table-first-cell-padding !important;

        &.text,
        &.currency {
          .mat-sort-header-container > .mat-sort-header-button {
            text-align: left;
          }
        }

        &.number {
          .mat-sort-header-container > .mat-sort-header-button {
            text-align: right;
          }
        }
      }
    }

    .mat-row {
      height: auto;

      &:nth-child(even) {
        .mat-cell {
          background-color: $hub-card-bg-color;
        }
      }

      .mat-cell {
        font-size: 11px;
        color: $hub-card-value-color;
        padding: $hub-table-first-cell-padding !important;
        border: none;

        &.projection {
          color: #8db3c7;
        }

        &.text {
          text-align: left;
        }

        &.number {
          text-align: right;
        }
      }
    }
  }

  .options {
    margin-bottom: $hub-table-options-margin;

    .table-view.mat-form-field {
      background-color: $hub-card-bg-color;
      border: 1px solid $hub-card-border-color;
      border-radius: $hub-card-border-radius;
      padding: 0 4px 0 8px;
      height: 26px;

      .mat-form-field-wrapper {
        padding-bottom: 0;

        .mat-form-field-infix {
          border: none;
          padding: 4px 0;

          .mat-select {
            font-family: 'Open Sans', sans-serif;
            font-size: 12px;
          }
        }

        .mat-form-field-underline {
          display: none;
        }
        .mat-form-field-subscript-wrapper {
          display: none;
        }
        .mat-form-field-hint-wrapper {
          display: none;
        }
      }
    }
  }
}

.hub-table-detail.mat-button-toggle-group {
  box-shadow: none;
  border: 1px solid $hub-card-border-color;

  &:not(.icon) {
    .mat-button-toggle + .mat-button-toggle {
      border-left: 1px solid $hub-card-border-color;
    }
  }

  .mat-button-toggle {
    padding: 4px 8px;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: normal;
    height: 18px;
    color: $hub-card-subtitle-color;
    background-color: $hub-card-bg-color;

    &.mat-button-toggle-disabled {
      opacity: 0.5;
    }

    &:hover {
      background-color: $hub-card-hover-bg-color;
      color: $hub-card-value-color;
      transition: background-color 0.3s;
    }

    &.mat-button-toggle-checked {
      background-color: $hub-card-hover-bg-color;
      color: $hub-card-value-color;
    }

    .mat-button-toggle-button {
      .mat-button-toggle-label-content {
        line-height: 18px;

        .mat-icon {
          font-size: 16px;
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

.notification-list-container {
  .mat-list-option:nth-child(even) {
    background-color: #f6f9fc !important;
  }
  .mat-list-option:nth-child(odd) {
    background-color: #ffffff !important;
  }
}

.school-view-options.mat-form-field {
  background-color: $hub-card-bg-color;
  border: 1px solid $hub-card-border-color;
  border-radius: $hub-card-border-radius;
  padding: 0 4px 0 8px;
  height: 26px;
  line-height: 18px;

  .mat-form-field-wrapper {
    padding-bottom: 0;

    .mat-form-field-infix {
      border: none;
      padding: 4px 0;

      .mat-select {
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
      }
    }

    .mat-form-field-underline {
      display: none;
    }
    .mat-form-field-subscript-wrapper {
      display: none;
    }
    .mat-form-field-hint-wrapper {
      display: none;
    }
  }
}

.school-view-info-tooltip {
  margin-top: -6px !important;
  margin-left: -8px !important;
  font-size: 11px;

  &.reversed {
    margin-top: 6px !important;
  }

  &.h-reversed {
    margin-left: 8px !important;
  }

  &.fixed {
    padding-right: 10px !important;
  }
}

.flag-tooltip {
  margin-top: 9px !important;
  margin-left: -8px !important;
  font-size: 11px;
}

.storm-tooltip {
  margin-top: -6px !important;
  margin-left: 8px !important;
  font-size: 11px;
}

.hub-card-tooltip {
  margin-top: -3px !important;
}

.storm-procurement-tooltip {
  margin-top: 6px !important;
}

.user-settings-tooltip {
  margin-left: -4px !important;
  font-size: 11px;
}

.vision-cluster-list-tooltip {
  margin-top: -2px !important;
  margin-left: 0 !important;
  font-size: 11px;
}

.vision-cluster-list-endorsement-tooltip {
  margin-top: -4px !important;
  margin-left: 8px !important;

  font-size: 11px;
}

.stream-tooltip {
  margin-left: 12px;
  margin-top: -4px;
}

.wide-tooltip {
  max-width: 400px !important;
  margin-top: -6px !important;
  margin-left: -8px !important;
  font-size: 11px;

  &.right {
    margin-left: 8px !important;
  }

  &.ref-layer {
    margin-top: 0 !important;
  }

  &.legend.reversed {
    margin-top: 6px !important;
  }

  &.fixed {
    padding-right: 10px !important;
  }
}

.question-tooltip {
  max-width: 400px !important;
  margin-top: 3px !important;
  font-size: 11px;

  &.right {
    margin-left: 14px !important;
  }
}

.multi-line-tooltip {
  white-space: pre-line;
}
