.table-container {
  &.row-hover {
    table > tbody > tr {
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1) !important;
      }
    }
  }
}
