.ps--active-y > .ps__rail-y {
  left: auto !important; // Prevent vertical scrollbar from being on the left
}

.dialog-scroll.ps--active-y > .ps__rail-y {
  right: 6px !important;
}

// Force scrollbar to be on top of other elements
.ps--active-y > .ps__rail-y,
.ps--active-x > .ps__rail-x {
  z-index: 1000;
}

.cards-data-container {
  .ps__rail-x {
    height: 10px;
  }

  .ps__rail-x:hover > .ps__thumb-x,
  .ps__rail-x:focus > .ps__thumb-x,
  .ps__rail-x.ps--clicking .ps__thumb-x {
    background-color: #999;
    height: 6px;
  }

  .ps-content {
    width: 100%;
    height: 100%;
  }
}
