.mat-button.storm-add-button {
  color: #2a81bd !important;
  background-color: #ffffff;
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 12px;
  line-height: 18px;
  padding: 8px !important;
  margin-top: 8px;
  border: 1px solid #2a81bd;
  width: 100%;

  &:hover .mat-button-focus-overlay {
    opacity: 0.1;
  }

  .mat-button-focus-overlay {
    background-color: #2a81bd;
  }

  &[disabled] {
    opacity: 0.4;
  }

  mat-icon {
    font-size: 12px !important;
    line-height: 18px;
    font-style: normal;
    font-weight: bold;
    height: 18px;
  }

  span {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.storm-expansion-panel {
  .mat-expansion-panel-body {
    padding: 8px 0;
  }
}

.storm-procurement-dialog {
  max-width: 90vw !important;
  .mat-dialog-container {
    overflow-y: hidden !important;
  }
}

.storm-procurement-add-new-item-dialog {
  width: 604px !important;
  height: 276px !important;
  border-radius: 4px !important;
}

.search-filter.storm {
  .mat-form-field-wrapper {
    width: 320px !important;
  }
}

.storm-input-container,
.storm-page-input {
  .column {
    width: 100%;
  }

  .title {
    font-family: 'Open Sans', Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    width: 220px;
    min-width: 220px;
    padding-top: 8px;
    color: #2e4866;
  }

  .tooltip-text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .action-button {
      font-size: 8px;
      margin: 0;
      padding-top: 0;
    }
  }

  &.vertical {
    .title {
      margin-bottom: 8px;
    }
  }

  .mat-error {
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    margin-top: 8px;
    color: rgb(230, 65, 115);
  }

  .action-button {
    padding-top: 8px;
    font-family: 'Open Sans', Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }

  // layer reference tooltip editor toggle hack
  .editor-header {
    margin-bottom: 8px;
    .action-button {
      padding-top: 0;
    }
  }
}

.storm-input-container {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &.vertical {
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &.large-bottom-margin {
    margin-bottom: 12px !important;
  }

  mat-radio-group.radio-group {
    width: 200px;
    height: 32px;
    font-size: 12px;
    line-height: 32px;

    .mat-radio-button:not(:last-child) {
      margin-right: 12px;
    }
  }
}

.storm-page-input {
  width: 100%;

  &.margin-bottom {
    margin-bottom: 8px;
  }

  &.checkbox {
    font-size: 12px;
    margin-bottom: 8px;
  }

  &.autocomplete,
  &.date-picker {
    max-width: 280px;
  }

  &.date-picker {
    font-size: 12px;

    .mat-form-field-suffix {
      right: 2px;
      top: 2px;
    }
  }

  &.multiple {
    width: 136px !important;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &.vertical {
    &.condensed {
      width: auto;

      .mat-form-field-infix {
        width: auto !important;
        max-width: 72px;
      }
    }

    .title {
      padding-top: 0;
      margin-bottom: 8px;
      width: auto !important;
      min-width: 0 !important;
    }

    .mat-form-field-infix {
      flex-direction: column;
    }
  }

  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }

  .mat-form-field-infix {
    width: 100% !important;
    border-top: none;
    display: flex;
    flex-direction: row;
    padding: 1px 0 0 0;
  }

  .mat-form-field-suffix {
    position: absolute;
    right: 6px;
    top: 7px;

    .mat-icon {
      font-size: 16px;
      width: 16px;
      height: 16px;
    }
  }

  .mat-form-field-subscript-wrapper {
    position: relative;
    margin-top: 0;
    top: auto;
    // margin-bottom: 12px;
  }

  .input-cell,
  .text-area,
  .select-box {
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 12px;
    background: #ffffff;
    border: 1px solid rgba(136, 152, 170, 0.4);
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 8px;
    padding-right: 8px;

    &[disabled],
    &.mat-select-disabled {
      background-color: #fafafa !important;
    }

    &.ng-invalid:not(.ng-untouched) {
      border-color: rgba(230, 65, 115, 0.6) !important;
    }
  }

  .input-cell {
    width: 100%;
    max-width: 280px;
    height: 32px;
  }

  .text-area {
    width: 100%;
    max-width: 480px;
    height: 128px;
    padding-top: 6px;
    padding-bottom: 6px;
    line-height: 1.5;
    resize: none;

    &.short {
      height: 64px;
    }
  }

  .select-box {
    width: 100%;
    max-width: 280px;
    height: 32px;
    line-height: 32px;

    &.select-box-with-word-wrap {
      height: unset;
      padding-top: 6px;
      padding-bottom: 6px;
      line-height: 200%;

      .mat-select-value-text {
        white-space: normal;
      }
    }
  }
}

.storm-input-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px 30px;
  padding: 10px 24px;

  .storm-input-container {
    flex-direction: row;
    display: flex;
    align-items: center;
    min-height: 32px;
    margin-bottom: 0;

    .title {
      padding-top: 0;
    }

    .display-only {
      margin: 0;
      white-space: pre-line;
    }

    .storm-page-input .text-area {
    }
  }

  .storm-input-grid-group {
    .storm-input-container {
      margin: 12px 0;

      &.storm-input-grid-header {
        margin-bottom: 15px;
        .title,
        .display-only {
          font-size: 13px;
          line-height: 17px;
        }
      }
    }
  }
}

.mat-select-panel .mat-option.select-option-word-wrap {
  height: unset;
  line-height: 200%;
  padding-top: 6px;
  padding-bottom: 6px;

  .mat-option-text {
    white-space: normal;
  }
}

.storm-autocomplete {
  .mat-option {
    height: 36px;
    line-height: 36px;

    .mat-option-text {
      font-family: 'Open Sans', Arial, sans-serif;
      font-size: 12px;
    }
  }
}

.display-only {
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 12px;
  background: #ffffff;
  margin: 8px;
  color: #2e4866;

  a {
    .mat-icon {
      font-size: 12px;
    }
  }
}
