@import 'hub/_colors.scss';

.highcharts-tooltip-container {
  z-index: 1000;

  svg {
    font-family: 'Open Sans', sans-serif !important;
  }

  .highcharts-tooltip {
    .highcharts-tooltip-box {
      fill: white !important;
      fill-opacity: 0.9 !important;
    }

    span {
      font-family: 'Open Sans', sans-serif !important;
    }
  }

  .transport-results-tooltip {
    white-space: normal;
    display: flex;
    flex-direction: column;
    min-width: 270px;

    .mode {
      color: #999;
    }

    .header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 8px;

      .number {
        color: white;
        border-radius: 2px;
        padding: 2px 4px;
        margin-right: 8px;
        font-weight: bold;
      }

      .description {
        display: flex;
        flex-direction: column;

        .title {
          font-weight: bold;
        }
        .subtitle {
          color: #999;
        }
      }
    }

    .row {
      display: flex;
      flex-direction: column;

      .header {
        font-weight: bold;
      }
    }
  }
}

.user-analytics-chart {
  .highcharts-background {
    fill: transparent;
  }

  .highcharts-data-label {
    text {
      font-family: 'Nunito', sans-serif;
      font-weight: 600 !important;
      font-size: 11px !important;
      fill: #ffffff !important;
    }

    .highcharts-text-outline {
      display: none;
    }
  }

  .highcharts-yaxis-labels,
  .highcharts-xaxis-labels {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 10px !important;
    }
  }

  .highcharts-yaxis {
    text {
      font-family: 'Nunito', sans-serif;
      font-size: 10px !important;
    }
  }
}

.population-chart {
  .highcharts-background {
    fill: transparent;
  }

  .highcharts-data-label {
    text {
      font-family: 'Nunito', sans-serif;
      font-weight: 600 !important;
      font-size: 11px !important;
      fill: #ffffff !important;
    }

    .highcharts-text-outline {
      display: none;
    }
  }

  .highcharts-tooltip {
    text {
      font-family: 'Open Sans', sans-serif;
    }
  }

  .highcharts-series .highcharts-point {
    stroke: $hub-card-bg-color;
  }
}

.hub-enrolment-chart {
  .highcharts-background {
    fill: transparent;
  }

  .highcharts-xaxis-labels {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 10px !important;
    }
  }

  .highcharts-yaxis-labels {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 10px !important;
    }
  }

  .highcharts-legend-item {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 11px !important;
      font-weight: 600 !important;
    }
  }

  .highcharts-axis {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 10px !important;
    }
  }

  .highcharts-tooltip {
    text {
      font-family: 'Open Sans', sans-serif;
    }
  }

  .highcharts-column-series {
    > rect {
      rx: 2px;
      ry: 2px;
    }
  }

  // Catchment population projections
  .highcharts-series-4 {
    path {
      stroke-dasharray: 4, 6;
      stroke-width: 2;
    }
  }

  // Changes for Learning Env Chart
  // .highcharts-series-8  {
  //   path {
  //     //stroke-dasharray: 4, 6;
  //     stroke-width: 0;
  //     stroke: none;
  //   }
  // }
}

.hub-staff-separation-chart {
  .highcharts-background {
    fill: transparent;
  }

  .highcharts-xaxis-labels {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 10px !important;
      font-weight: 600 !important;
    }
  }

  .highcharts-yaxis-labels {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 10px !important;
    }
  }

  .highcharts-legend-item {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 11px !important;
      font-weight: 600 !important;
    }
  }

  .highcharts-axis-line {
    stroke: none;
  }

  .highcharts-axis {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 10px !important;
    }
  }

  .highcharts-tooltip {
    text {
      font-family: 'Open Sans', sans-serif;
    }
  }

  .highcharts-bar-series {
    > rect {
      rx: 2px;
      ry: 2px;
    }
  }
}

//#9455
.hub-enrolment-chart-new {
  .highcharts-background {
    fill: transparent;
  }
  .highcharts-xaxis-labels {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 10px !important;
    }
  }
  .highcharts-yaxis-labels {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 10px !important;
    }
  }
  .highcharts-legend-item {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 11px !important;
      font-weight: 600 !important;
    }
  }
  .highcharts-axis {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 10px !important;
    }
  }
  .highcharts-tooltip {
    text {
      font-family: 'Open Sans', sans-serif;
    }
  }
  .highcharts-column-series {
    > rect {
      rx: 2px;
      ry: 2px;
    }
  }
}

.hub-naplan-chart {
  .highcharts-background {
    fill: transparent;
  }

  .highcharts-xaxis-labels {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 10px !important;
    }
  }

  .highcharts-yaxis-labels {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 10px !important;
    }
  }

  .highcharts-legend-item {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 11px !important;
      font-weight: 600 !important;
    }
  }

  .highcharts-axis {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 10px !important;
    }
  }

  .highcharts-tooltip {
    text {
      font-family: 'Open Sans', sans-serif;
    }
  }

  .highcharts-column-series {
    > rect {
      rx: 2px;
      ry: 2px;
      stroke: none;
      stroke-width: 0;
    }
  }

  .highcharts-columnrange-series {
    > rect {
      rx: 2px;
      ry: 2px;
      stroke: none;
      stroke-width: 0;
    }
  }

  .highcharts-grid {
    display: none;
  }

  .highcharts-plot-line {
    stroke-width: 1px !important;
    z-index: 5;
  }

  .highcharts-plot-line-label,
  .highcharts-plot-band-label {
    font-family: 'Open Sans', sans-serif;
    font-size: 9px;
  }

  .highcharts-plot-line-label {
    fill: #e84646;
  }

  .highcharts-plot-band-label {
    fill: #a9b4c1;
  }

  .highcharts-plot-bands-0 {
    .highcharts-plot-band:nth-child(even) {
      fill: #f6f9fc;
      fill-opacity: 1;
    }
    .highcharts-plot-band:nth-child(odd) {
      fill: #ffffff;
    }
  }

  .highcharts-point {
    stroke-width: 0 !important;
  }
}

#secondary-sparkline {
  overflow: visible !important;
}

.admin-user-analytics-chart {
  .highcharts-axis,
  .highcharts-axis-labels {
    text {
      font-family: 'Open Sans', sans-serif !important;
      font-size: 10px !important;
      fill: $hub-card-subtitle-color !important;
    }
  }
  .highcharts-xaxis-labels {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 10px !important;
      fill: $hub-card-subtitle-color !important;
    }
  }
  .highcharts-label {
    text {
      font-family: 'Nunito', sans-serif;
      font-size: 10px !important;
      font-weight: 700 !important;
    }
  }
}

.hub-sparkline {
  overflow: visible !important;

  > svg {
    overflow: visible;
  }

  .highcharts-background {
    fill: transparent;
  }
  .highcharts-tick,
  .highcharts-grid,
  .highcharts-axis-line {
    display: none;
  }
  .highcharts-xaxis-labels {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 10px !important;
      fill: $hub-card-subtitle-color !important;
      display: none;

      &:first-child,
      &:last-child {
        display: block;
      }
    }
  }
  .highcharts-label {
    display: none;

    &:first-child,
    &:last-child {
      display: block;
    }

    text {
      font-family: 'Nunito', sans-serif;
      font-size: 10px !important;
      font-weight: 700 !important;
    }
  }

  .highcharts-markers {
    > path.highcharts-point {
      stroke: none;

      &:not(:first-of-type):not(:last-of-type) {
        display: none !important;
      }
    }
  }

  .highcharts-series {
    path {
      stroke-width: 2px;
    }
  }

  &.primary-sparkline {
    .highcharts-label {
      text {
        fill: $hub-sparkline-primary-color !important;
      }
    }

    .highcharts-series {
      path:first-child {
        stroke: $hub-sparkline-primary-color !important;
      }
    }

    .highcharts-markers {
      path {
        fill: $hub-sparkline-primary-color;
      }
    }
  }

  &.secondary-sparkline {
    .highcharts-label {
      text {
        fill: $hub-sparkline-secondary-color !important;
      }
    }

    .highcharts-series {
      path:first-child {
        stroke: $hub-sparkline-secondary-color !important;
      }
    }

    .highcharts-markers {
      path {
        fill: $hub-sparkline-secondary-color;
      }
    }
  }
}

.hub-projections {
  .highcharts-background {
    fill: transparent;
  }
  .highcharts-tick,
  .highcharts-grid,
  .highcharts-axis-line {
    display: none;
  }
  .highcharts-xaxis-labels {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 10px !important;
      fill: $hub-card-subtitle-color !important;
      display: none;

      &:first-child,
      &:last-child {
        display: block;
      }
    }
  }
  .highcharts-label {
    display: none;

    &:first-child,
    &:last-child {
      display: block;
    }

    text {
      font-family: 'Nunito', sans-serif;
      font-size: 10px !important;
      font-weight: 700 !important;
    }
  }

  .highcharts-color-1 {
    fill: $hub-projections-chart-non-gov-color;
    stroke: $hub-projections-chart-non-gov-color;
  }

  .highcharts-label {
    text {
      tspan {
        stroke: white;
      }
    }
  }

  &.primary-projections-chart {
    .highcharts-color-0 {
      fill: $hub-sparkline-primary-color;
    }

    .highcharts-label {
      text {
        fill: $hub-sparkline-primary-color !important;
      }
    }
  }

  &.secondary-projections-chart {
    .highcharts-color-0 {
      fill: $hub-projections-secondary-color;
    }

    .highcharts-label {
      text {
        fill: $hub-projections-secondary-color !important;
      }
    }
  }

  &.primary-utilisation-chart {
    .highcharts-color-0 {
      fill: $hub-sparkline-primary-color;
    }

    .highcharts-label {
      text {
        fill: $hub-sparkline-primary-color !important;
      }
    }
  }

  &.secondary-utilisation-chart {
    .highcharts-color-0 {
      fill: $hub-sparkline-secondary-color;
    }

    .highcharts-label {
      text {
        fill: $hub-sparkline-secondary-color !important;
      }
    }
  }

  &.age-early-childhood-chart {
    .highcharts-color-0 {
      fill: $hub-sparkline-primary-color;
    }

    .highcharts-label {
      text {
        fill: $hub-sparkline-primary-color !important;
      }
    }
  }

  &.enrolment-early-childhood-chart {
    .highcharts-color-0 {
      fill: $hub-sparkline-primary-color;
    }

    .highcharts-label {
      text {
        fill: $hub-sparkline-primary-color !important;
      }
    }
  }
}

.hub-minor-works {
  .highcharts-background {
    fill: transparent;
  }

  .highcharts-title {
    text-align: center;
    font-family: 'Open Sans', sans-serif !important;
    top: auto !important;
    bottom: 0;
    left: 0 !important;
    width: 100%;
    line-height: 1.25;
    pointer-events: none;

    .number {
      font-weight: 600;
      color: #2e4866;
    }
  }

  .highcharts-color-0 {
    fill: #9152ab;
  }

  .highcharts-color-1 {
    fill: #29bcb0;
  }

  .highcharts-color-2 {
    fill: #47b0e7;
  }

  .highcharts-color-3 {
    fill: #f5b606;
  }
}

.hub-gnaf {
  .highcharts-background {
    fill: transparent;
  }
  .highcharts-tick,
  .highcharts-grid,
  .highcharts-axis-line {
    display: none;
  }
  .highcharts-xaxis-labels {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 10px !important;
      fill: $hub-card-subtitle-color !important;
      display: none;

      &:first-child,
      &:last-child {
        display: block;
      }
    }
  }
  .highcharts-label {
    display: none;

    &:first-child,
    &:last-child {
      display: block;
    }

    text {
      fill: #2dcad3 !important;
      font-family: 'Nunito', sans-serif;
      font-size: 10px !important;
      font-weight: 700 !important;
      transform: translateX(12px);

      tspan {
        stroke: white;
      }
    }
  }
}

.hub-industries {
  .highcharts-background {
    fill: transparent;
  }

  .highcharts-axis-line,
  .highcharts-plot-border,
  .highcharts-grid-line {
    display: none;
  }

  .highcharts-legend-item {
    text {
      font-family: 'Nunito', sans-serif;
      font-size: 10px !important;
      transform: translateY(-2px);
      font-weight: normal !important;
      fill: #666666 !important;
    }

    > .highcharts-point {
      rx: 2px !important;
      ry: 2px !important;
      width: 8px;
      height: 8px;
      transform: translate(6px, 2px);
    }
  }

  .highcharts-axis-labels {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 10px !important;
      fill: #8898aa !important;
      text-decoration: none !important;
      font-weight: normal !important;
    }
  }

  .highcharts-button {
    cursor: pointer;
    translate: opacity 0.25s ease;

    &:not(:hover) {
      opacity: 0.5;
    }

    &:hover {
      opacity: 1;
    }

    > rect {
      height: 28px;
      transform-origin: top center;
      transform: scale(0.75);
    }

    > text {
      font-family: 'Open Sans', sans-serif;
      font-size: 10px !important;
      fill: #8898aa !important;
      transform-origin: top center;
      transform: scale(0.75);
    }
  }
}

.hub-occupation {
  .highcharts-background {
    fill: transparent;
  }

  .highcharts-axis-line,
  .highcharts-plot-border,
  .highcharts-grid-line {
    display: none;
  }

  .highcharts-legend {
    > g {
      transform: translateX(12px);
    }

    .highcharts-axis-labels {
      transform: translateX(-4px);

      text {
        &:first-child {
          transform: translateY(2px);
        }

        &:last-child {
          transform: translateY(-6px);
        }
      }
    }
  }

  .highcharts-axis-labels {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 10px !important;
      fill: #8898aa !important;
    }
  }
}

.measure-panel-transport-chart {
  .highcharts-data-labels {
    .highcharts-label {
      span {
        font-family: 'Nunito', sans-serif !important;
        font-size: 10px !important;
        top: 6px !important;
        left: 2px !important;
        font-weight: normal !important;
      }
    }

    .walk-data-label {
      span {
        text-shadow:
          -1px -1px 0 #fff,
          1px -1px 0 #fff,
          -1px 1px 0 #fff,
          1px 1px 0 #fff;
        color: #181818 !important;
      }
    }
  }

  .highcharts-series {
    rect {
      rx: 3px;
      ry: 3px;
    }
  }
}

.hub-housing-supply-card-chart {
  .highcharts-background {
    fill: transparent;
  }
  .highcharts-tick,
  .highcharts-grid,
  .highcharts-axis-line {
    display: none;
  }

  .highcharts-xaxis-labels {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 10px !important;
      fill: $hub-card-subtitle-color !important;
      display: none;

      &:first-child,
      &:last-child {
        display: block;
      }
    }
  }
  .highcharts-label {
    display: none;

    &:first-child,
    &:last-child {
      display: block;
    }

    text {
      fill: #2dcad3 !important;
      font-family: 'Nunito', sans-serif;
      font-size: 10px !important;
      font-weight: 700 !important;
      transform: translateX(12px);

      tspan {
        stroke: white;
      }
    }
  }
}

//CSS for ECO hub card charts
.hub-early-childhood-chart {
  .highcharts-background {
    fill: transparent;
  }

  .highcharts-tick,
  .highcharts-grid,
  .highcharts-axis-line {
    display: none;
  }

  .highcharts-xaxis-labels {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 10px !important;
      fill: #8898aa !important;
    }
  }

  .highcharts-yaxis-labels {
    text {
      font-family: 'Open Sans', sans-serif;
      font-size: 10px !important;
      fill: #8898aa !important;
    }
  }
}
