// TO DO - Split up into separate files per modules

$page-header-height: 36px;
$router-content-padding: 12px;
$menu-width: 86px;
$menu-tile-height: 84px;
$menu-tile-icon-size: 28px;
$eagle-eye-logo-width: 64px;
$eagle-eye-logo-height: 58px;
$eagle-eye-logo-margin-v: 12px;
$eagle-eye-logo-text-size: 12px;
$eagle-eye-title-margin-v: 8px;
$eagle-eye-subtitle-margin: 42px;

$ee-tooltip-max-width: 250px;
$ee-tooltip-padding: 8px;
$ee-tooltip-arrow-top: 16px;

$mat-small-button-width: 32px;
$mat-small-button-icon-width: 18px;

$content-panel-width: 580px; //510 for only school-outcomes
$content-panel-header-height: 56px;
$content-panel-header-padding: 24px;
$content-panel-header-font-size: 14px;
$content-panel-group-heading-font-size: 14px;
$content-panel-field-margin-h: 15px;
$content-panel-field-margin-v: 8px;
$content-panel-group-margin-v: 8px;
$content-panel-field-font-size: 11px;
$content-panel-field-inner-padding: 8px;
$content-panel-field-inner-padding-v: 4px;
$content-panel-field-width: 245px;
$content-panel-label-width: 130px;
$content-panel-geo-field-max-height: 200px;
$content-panel-button-container-height: 60px;
$content-panel-button-container-padding: 12px;
$content-panel-button-container-padding-v: 14px;
$content-panel-button-container-padding-h: 20px;
$content-fields-suffix-margin: 8px;
$content-panel-expand-button-width: 16px;
$content-panel-expand-button-height: 48px;

$tab-group-icon-size: 18px;
$tab-group-icon-margin: 4px;

$planned-area-add-button-margin-h: 12px;
$planned-area-add-button-margin-v: 32px;
$pa-field-data-loading-spinner-size: 48px;
$pa-field-data-loading-spinner-padding: 40px;

$pa-download-button-container-size: 42px;
$pa-download-button-size: 24px;
$pa-download-button-icon-size: 16px;

$pa-map-annotation-popup-width: 250px;
$pa-map-annotation-popup-padding: 8px;
$pa-map-annotation-popup-arrow-size: 8px;

$map-control-margin: 12px;
$map-control-margin-bottom: 32px;
$button-arrow-icon-margin: 4px;

$map-ref-layer-padding-v: 4px;
$map-ref-layer-padding-h: 12px;
$ref-layer-panel-padding-bottom: 8px;

$map-ref-search-container-padding: 12px;
$map-ref-no-results-container-padding: 8px 12px;
$map-ref-search-padding-v: 4px;
$map-ref-search-padding-h: 8px;
$map-ref-category-heading-height: 24px;
$map-ref-category-heading-line-height: 24px;
$map-ref-category-heading-icon-margin: 8px;
$map-ref-legend-size: 16px;
$map-ref-legend-margin: 8px;
$map-ref-loading-spinner-size: 18px;
$map-ref-info-size: 12px;
$map-ref-list-item-height: 30px;
$mat-ref-list-item-padding-h: 8px;

$map-search-width: 235px;
$map-search-height: 32px;
$map-search-border-radius: 4px;
$map-search-padding-h: 12px;
$map-search-padding-v: 8px;
$map-search-clear-v-margin: 1px;
$map-search-clear-icon-size: 16px;
$map-search-button-size: 32px;
$map-search-button-padding-h: 12px;
$map-search-type-margin-v: 4px;
$map-search-icon-size: 14px;
$map-search-autocomplete-panel-width: 318px;
$map-search-autocomplete-panel-top-margin: 4px;
$map-search-result-icon-size: 16px;

$map-basemap-size: 96px;
$map-basemap-padding: 2px;
$map-basemap-panel-width: 360px;

$map-controls-box-shadow-radius: 2px;

$map-control-panel-height: 32px;
$map-control-panel-width: 40px;

$map-measure-panel-padding: 6px;
$map-measure-title-padding-v: 2px;
$map-measure-title-padding-h: 4px;
$map-measure-title-margin: 10px;
$map-measure-data-height: auto;

$map-copyright-height: 20px;

$map-panel-drop-shadow-width: 4px;

/* Map Controls */

/* Toolbar and toolbar panels */
$map-toolbar-width: 32px;

$map-toolbar-panel-right: 42px;
$map-toolbar-panel-padding-bottom: 8px;
$map-toolbar-arrow-right: -16px;
$map-toolbar-arrow-width: 8px;
$map-toolbar-panel-h3-margin-top: 8px;

$map-toolbar-ref-layer-panel-width: 330px;
$map-toolbar-ref-layer-panel-arrow-top: 40px;

$map-toolbar-basemap-panel-width: 360px;
$map-toolbar-basemap-panel-arrow-top: 72px;
$map-toolbar-basemap-image-size: 72px;
$map-toolbar-basemap-padding-right: 8px;
$map-toolbar-basemap-label-height: 30px;

/* Instructions panel */
$instructions-panel-width: 300px;
$instructions-panel-padding: 4px 8px;
$instructions-panel-icon-margin: 4px;

/* Notification panel */
$notification-panel-min-width: 250px;
$notification-panel-max-width: 320px;
$notification-panel-max-height: 500px;
$notification-panel-padding: 16px;

/* Map Legend Panel */
$map-layer-color-square-size: 8px;
$map-layer-color-line-width: 10px;
$map-layer-color-line-height: 4px;
$map-layer-color-square-border-width: 1px;
$map-layer-color-square-border-radius: 2px;
$map-legend-panel-padding: 8px;

$map-legend-icon-container-size: 12px;
$map-legend-icon-border-radius: 50%;
$map-legend-icon-border-width: 1px;
$map-legend-icon-font-size: 7px;

$map-legend-title-font-size: 14px;

/* Map Spinner */
$map-loading-spinner-size: 18px;

/* Dialog */
$dialog-title-icon-size: 20px;
$dialog-title-icon-margin: 8px;
