// TO DO - Split up into separate files per modules

/* Menu */
$eagle-eye-dark-blue: #273d56;
$eagle-eye-light-blue: #a0adbb;
$eagle-eye-highlight-blue: #4aade5;
$eagle-eye-subtitle-color: rgba(255, 255, 255, 0.8);

$main-loading-spinner-color: white;
$main-fab-bg-gradient: linear-gradient(45deg, #3aa0aa, #584784);
$main-fab-bg-disabled-color: #cccccc;

$ee-tooltip-bg-color: rgba(#092039, 0.9);
$ee-tooltip-text-color: #ffffff;

$menu-bg-color: #44444a;
$menu-bg-gradient: linear-gradient(#3cb5c5, #594481);

$menu-tile-default-color: white;
$menu-tile-hover-color: rgba(255, 255, 255, 1);
$menu-tile-selected-color: rgba(255, 255, 255, 1);
$menu-tile-selected-bg-color: rgba(255, 255, 255, 0.05);
$menu-tile-selected-border-color: rgba(255, 255, 255, 1);

$map-menu-button-select-strip-color: #f2c94c;

$page-header-bg-color: #24374c;
$page-header-text-color: #ffffff;

$page-header-user-info-color: rgba(255, 255, 255, 0.8);

$content-panel-header-bg-color: #44444b;
$content-panel-header-text-color: #2a81bd;
$content-panel-bg-color: #ffffff;
$content-panel-button-container-bg-color: #dcdfe2;
$content-panel-group-heading-text-color: #8898aa;

$content-fields-bg-color: #f6f9fc;
$content-fields-border-color: rgba(136, 152, 170, 0.4);
$content-fields-text-color: #2e4866;
$content-fields-none-text-color: #cccccc;
$content-fields-bg-edit-color: #ffffff;
$content-fields-border-edit-color: rgba(136, 152, 170, 0.4);
$content-fields-focus-drop-shadow-color: rgba(74, 173, 229, 0.8);

$content-group-frame-color: rgba(0, 0, 0, 0.1);
$content-group-none-text-color: $eagle-eye-light-blue;

$buttons-key-action-bg-color: #2a81bd;
$buttons-key-action-text-color: #ffffff;
$buttons-key-action-border-color: #2a81bd;
$buttons-frame-bg-color: #2a81bd;
$buttons-frame-text-color: #2a81bd;
$buttons-frame-border-color: #2a81bd;
$buttons-warn-bg-color: rgba(187, 0, 0, 0.16);
$buttons-warn-text-color: #bb0000;
$buttons-warn-border-color: #bb0000;

$pa-annotation-popup-bg-color: #333333;

$map-search-bg-color: rgba(17, 30, 45, 0.5);
$map-search-text-color: #ffffff;
$map-search-icon-color: #ffffff;
$map-search-clear-color: #e5e5e5;
$map-search-placeholder-color: #cccccc;
$map-basemap-label-color: rgba(255, 255, 255, 0.8);
$map-panel-bg-color: #ffffff;

$map-ref-search-border-color: #467d9b;
$map-ref-search-icon-color: #467d9b;
$map-ref-search-text-color: #273d56;
$map-ref-search-placeholder-color: #8898aa;
$map-ref-search-clear-color: #666666;
$map-ref-loading-spinner-color: #34b7b0;

$map-measure-panel-bg-color: #ffffff;
$map-measure-title-bg-color: #47b0e7;

$map-street-view-no-image-bg-color: #000000;
$map-street-view-no-image-text-color: #ffffff;

$map-panel-close-icon-color: #8898aa;
$map-panel-drop-shadow-color: rgba(#273d56, 0.25);
$map-panel-drop-shadow-color-light: rgba(#273d56, 0.1);

/* Map Controls */
$map-controls-accent-color: #2e4866;

/* Toolbar and toolbar panels */
$map-toolbar-primary-action-button-color: #47b0e7;
$map-toolbar-primary-action-button-hover-color: #2a81bd;
$map-toolbar-primary-action-icon-color: #ffffff;

/* Instructions panel */
$map-instructions-icon-color: white;
$map-instructions-panel-bg-color: #092039;

/* Notifications panel */
$notification-panel-success-bg-color: #72b671;
$notification-panel-error-bg-color: #cc5e55;
$notification-panel-info-bg-color: #43b4ec;

/* Map Legend Panel */
$icon-non-gov-schools-bg-color: #0252ba;
$icon-non-gov-schools-border-color: #003880;
$icon-non-gov-schools-icon-color: #fcfcfc;
$map-legend-list-border-color: #e5eaf1;

/* Dialog */
$dialog-title-color: #2a81bd;

$tooltip-alert-color: #e64173;
