@import 'ee-colors';

/* For map tooltip */
.tooltip .tooltip-text .tooltip-content table {
  font-size: 9px;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #2e4866;
    padding: 2px;
  }
}

.toolbar-tooltips,
.toolbar-primary-tooltip {
  margin-top: 4px;

  &.left {
    margin-left: -8px;
  }

  &.right {
    margin-left: 8px;
  }
}

.tooltip-container {
  &.toolbar-primary-tooltip {
    font-weight: bold;
    background-color: $map-toolbar-primary-action-button-hover-color !important;
  }

  &.tooltip-alert {
    background-color: $tooltip-alert-color !important;

    &.legend-panel {
      margin-left: 8px !important;
      margin-top: -6px !important;
    }
  }

  &.button-tooltip {
    margin-top: 4px;
  }

  &.left {
    &.toolbar-primary-tooltip {
      &::after {
        border-left-color: $map-toolbar-primary-action-button-hover-color !important;
      }
    }

    &.tooltip-alert {
      &::after {
        border-left-color: $tooltip-alert-color !important;
      }
    }
  }

  &.right {
    &.toolbar-primary-tooltip {
      &::after {
        border-right-color: $map-toolbar-primary-action-button-hover-color !important;
      }
    }

    &.tooltip-alert {
      &::after {
        border-right-color: $tooltip-alert-color !important;
      }
    }
  }
}
