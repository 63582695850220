.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-ctrl-bottom-left {
  .mapboxgl-ctrl {
    margin: 0;
  }

  .mapboxgl-ctrl-scale {
    border-style: none solid solid;
    border-color: gray !important;
    clear: none !important;
    line-height: 14px;
    box-shadow: -4px 1px 0px 4px rgba(255, 255, 255, 0.75);
    margin: 0 5px 2px 5px !important;
  }
}
