.mce-content-body {
  font-size: 14px !important;
}

editor.disabled {
  .tox-tinymce .tox-editor-container .tox-editor-header {
    display: none;
  }
}

.vision-editor {
  .tox-tinymce.tox-fullscreen {
    height: calc(100% - 140px) !important;
    width: calc(100% - 86px) !important;
    top: 140px !important;
    left: 86px !important;
  }
}

.admin-editor {
  .tox-tinymce.tox-fullscreen {
    height: calc(100% - 36px - 42px - 1px) !important;
    width: calc(100% - 86px) !important;
    top: calc(36px + 42px + 1px) !important;
    left: 86px !important;
  }
}

.tox-tinymce {
  //height: 240px !important;

  &.tox-fullscreen {
    position: fixed;

    .tox-editor-container {
      .tox-sidebar-wrap {
        height: 100%;

        .tox-edit-area {
          height: 100%;

          iframe {
            height: 100% !important;
          }
        }
      }
    }
  }
}

.tox-tinymce .tox-editor-container .tox-toolbar-overlord .tox-toolbar__overflow,
.tox-tinymce .tox-editor-container .tox-toolbar-overlord .tox-toolbar__primary {
  background: url("data:image/svg+xml;charset=utf8,%3Csvg height='27px' viewBox='0 0 40 28px' width='40' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0' y='26px' width='100' height='28' fill='%23cccccc'/%3E%3C/svg%3E")
    left 0 top 0 #fff !important;

  .tox-toolbar__primary {
    border-top: none;
  }

  .tox-tbtn {
    width: 22px !important;
    height: 22px !important;

    &.tox-tbtn--select {
      width: 34px !important;
    }

    .tox-icon,
    .tox-tbtn__select-chevron,
    .tox-split-button__chevron {
      svg {
        transform: scale(0.75);
      }
    }
  }
}

.vision-editor {
  &.curved-border {
    .tox-tinymce {
      border-radius: 4px;
    }
  }

  &.large {
    height: calc(100% - 32px - 8px);

    .tox-tinymce:not(.tox-fullscreen) {
      height: 100% !important;
    }
  }

  &.disabled {
    .tox-toolbar {
      display: none;
    }

    .tox .tox-edit-area {
      border-top: none;
    }
  }
}
