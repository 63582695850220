.introjs-hint {
  z-index: 1000;

  .introjs-hint-pulse {
    border: 5px solid rgba(235, 192, 57, 0.49);
    background-color: rgba(235, 192, 57, 0.36);

    &:hover {
      border: 5px solid rgba(50, 73, 102, 0.3);
      background-color: rgba(235, 192, 57, 0.36);
    }
  }
}

.introjs-hint:hover > .introjs-hint-pulse {
  border: 5px solid rgba(50, 73, 102, 0.3);
}

.introjs-tooltip {
  width: 400px;
  max-width: 500px;

  .introjs-tooltiptext {
    p {
      margin: 0;
    }

    .help-title {
      i {
        margin-right: 3px;
        font-size: 14px;
        font-weight: 300;
      }

      margin: 3px 0 14px 0;
      font-size: 14px;
      font-weight: 600;
      color: #324966;
    }

    .help-gif {
      img {
        width: 100%;
      }
    }

    .introjs-button {
      margin: 2px 0;
      font-size: 1px;
      border: none;
      text-shadow: none;
      background: none;
      position: absolute;
      top: 12px;
      right: 10px;

      &::before {
        content: '\f00d';
        font-family: 'Font Awesome 6 Pro';
        font-size: 18px;
        color: #cfd6e1;
        font-weight: 300;
      }

      &:hover {
        box-shadow: none;

        &::before {
          color: #8898aa;
        }
      }
    }
  }
}
