html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  overflow: hidden;
}

[hidden] {
  display: none !important;
}

.bold {
  font-weight: bold;
}

.capitalise {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

ul {
  &.no-bullet {
    padding: 0;
    list-style: none;
  }
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
