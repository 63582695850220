/* HUB View */
$hub-view-heading-color: #2a81bd;
$hub-view-subheading-color: #8898aa;
$hub-view-text-color: #2e4866;
$hub-link-text-color: $hub-view-text-color;
$hub-link-text-highlight-color: #2a81bd;
$hub-card-bg-color: #f6f9fc;
$hub-card-border-color: #e5eaf1;
$hub-card-title-color: #8898aa;
$hub-card-value-color: $hub-view-text-color;
$hub-card-subtitle-color: #8898aa;
$hub-card-hover-bg-color: #e5ebf2;
$hub-card-hover-border-color: #cfd6e1;

$hub-sparkline-primary-color: #41b94f;
$hub-sparkline-secondary-color: #4e52b6;
$hub-projections-chart-non-gov-color: #a6b3c1;
$hub-projections-secondary-color: #5459ec;

$hub-table-header-border-color: #e5eaf1;

$hub-demographics-card-population-icon-color: #2a81bd;
$hub-demographics-card-household-icon-color: #2a81bd;

$hub-school-view-text-color: $hub-view-text-color;
$hub-school-view-hr-color: rgba(#8898aa, 0.35);
$hub-school-view-enrolment-arrow-color: #8898aa;
$hub-school-view-enrolment-arrow-disabled-color: #eeeeee;
$hub-school-view-enrolment-card-non-selectable-bg-color: repeating-linear-gradient(
    -45deg,
    #f6f9fc,
    #f6f9fc 10px,
    #ebeff5 10px,
    #ebeff5 20px
  )
  0 0/42px 50px;
$hub-school-view-enrolment-card-bg-color: #f6f9fc;
$hub-school-view-enrolment-card-hover-bg-color: #ebeff5;
$hub-school-view-enrolment-card-selected-bg-color: #47b0e7;
$hub-school-view-selected-enrolment-bg-color: #e64173;
$hub-school-view-enrolment-value-color: #2e4866;
$hub-school-view-noncensusenrolment-value-color: #1d58bf;
$hub-school-view-catchment-value-color: #43b850;

$pdf-icon-hover-color: #bb0000;
$info-icon-color: #8898aa;
$info-icon-hover-color: #273d56;

$hub-school-enrolment-chart-in-catch-color: #045284;
$hub-school-enrolment-chart-out-catch-color: #fcae7c;
$hub-school-enrolment-chart-pop-color: #000000;

$hub-student-profile-card-designated-color: #2e80bc;
$hub-student-profile-card-non-designated-color: #e64173;
$hub-student-profile-card-no-catchment-color: #f2b607;
